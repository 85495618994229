import React, {Component, Fragment} from 'react';
import ScorecardDetailsModal from "../ScorecardDetail/scorecardDetailModal";
import MetricDetailsModal from "../MetricDetailsModal";
import {ScorecardPreview} from "u-header";
import {STRAGILE_BASE_URL2} from "../../Constants";
import {connect} from "react-redux";

class EmbeddedScorecardDetailPage extends Component{
    constructor(props){
        super(props);

        this.state = {
            token: '',
            accordion: [],
            accordionSub: [],
            owners: [],
            contributors: [],
            viewers: [],
            scorecardTags: [],
            scorecardDomains: []
        }
    }



    metricDetails = (id) => {
        this.setState({
            tempMetricId: id,
            metricDetail: !this.state.metricDetail
        })
    };

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');
        this.setState({
            token: token
        });
    };

    viewScorecard = () => {
        this.setState({
            detailModal: !this.state.detailModal
        })
    };

    render() {
        return (
            <Fragment>
                {this.state.token &&
                <ScorecardPreview stragileURL={STRAGILE_BASE_URL2} scorecardAPIURL={"/embeddedScorecard?token="+this.state.token} stragileFlag={true} stragileAPIFlag={2} token={this.state.token} />
                }
                {ScorecardDetailsModal(this.state.detailModal, this.viewScorecard, this.state)}
                <MetricDetailsModal isOpen={this.state.metricDetail} toggle={this.metricDetails}
                                    metricId={this.state.tempMetricId}
                />
            </Fragment>
        );
    }
}
const mapStateToProps = state => ({
    userObj: state.UrlConstants.userdetailObject
});
export default connect(mapStateToProps)(EmbeddedScorecardDetailPage)
