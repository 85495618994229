import {
  toast,
  Slide
} from 'react-toastify';

export default function showToast(msg) {
  return toast(msg,{
    transition: Slide,
    closeButton: true,
    autoClose: 3000,
    position: 'top-center',

});
}

export function warningToast(msg) {
  return toast.error(msg,{
    transition: Slide,
    closeButton: true,
    autoClose: 3000,
    position: 'top-center',

  });
}

export function formateDate(date) {
  let approvedDate = new Date(date);
  let approvedDate_Date = (approvedDate.getDate() < 10 ? '0' : '')
      + approvedDate.getDate();
  let approvedDate_Month = (approvedDate.getMonth() < 10 ? '0' : '')
      + approvedDate.getMonth();
  let approvedDate_Year = approvedDate.getFullYear();
  let time = approvedDate;
  time = time.toLocaleString('en-US', {

    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  approvedDate_Month++;
  approvedDate = "  " + (approvedDate_Month < 10 ? '0' : '')
      + approvedDate_Month + "/" + approvedDate_Date + "/"
      + approvedDate_Year + "   " + time;
  return approvedDate;
}
export function formateDate2(date) {
  let approvedDate = new Date(date);
  let approvedDate_Date = (approvedDate.getDate() < 10 ? '0' : '')
      + approvedDate.getDate();
  let approvedDate_Month = (approvedDate.getMonth() < 10 ? '0' : '')
      + approvedDate.getMonth();
  let approvedDate_Year = approvedDate.getFullYear();

  approvedDate_Month++;
  approvedDate = "  " + (approvedDate_Month < 10 ? '0' : '')
      + approvedDate_Month + "-" + approvedDate_Date + "-"
      + approvedDate_Year;
  return approvedDate;
}
