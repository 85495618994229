import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ScorecardDetailsModal = (isOpen, toggle, state) => {

    return  <Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
                <ModalHeader toggle={toggle}>
                    Scorecard Details
                </ModalHeader>
                <ModalBody>
                    <table className="table table-hover table-bordered table-striped textLeft">
                        <tbody>
                        <tr>
                            <th>Title</th>
                            <td>{state.titleName}</td>
                        </tr>
                        <tr>
                            <th>Sub Title</th>
                            <td>{state.subTitleName}</td>
                        </tr>
                        <tr>
                            <th>Short Name</th>
                            <td>{state.shortName}</td>
                        </tr>
                        <tr>
                            <th>Owners</th>
                            <td>
                                {state.owners && state.owners.map(items => {
                                    return <span className="label label-info mr-2">{items.rootUsers.fname}{" "}{items.rootUsers.lname}</span>
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>Contributors</th>
                            <td>
                                {state.contributors && state.contributors.map(items => {
                                    return <span className="label label-info mr-2">{items.rootUsers.fname}{" "}{items.rootUsers.lname}</span>
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{state.privateSelected ? 'Private' : 'Public' }</td>
                        </tr>
                        {state.privateSelected ?
                            <tr>
                                <th>Viewers</th>
                                <td>
                                    {state.viewers && state.viewers.map(items => {
                                        return <span className="label label-info mr-2">{items.rootUsers.fname}{" "}{items.rootUsers.lname}</span>
                                    })}
                                </td>
                            </tr>
                            : undefined
                        }
                        <tr>
                            <th>Description</th>
                            <td>{state.description}</td>
                        </tr>
                        <tr>
                            <th>Tags</th>
                            <td>
                                {state.scorecardTags && state.scorecardTags.map(items => {
                                    return <span className="label label-info mr-2">{items.scorecardTags.tagName}</span>
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>Domains</th>
                            <td style={{overflow:"auto", maxWidth: '647px'}}>
                                {state.scorecardDomains && state.scorecardDomains.map(items => {
                                    return <span className="label label-info mr-2">{items.scorecardDomains.domainName}</span>
                                })}
                            </td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>
                                {state.scorecardStatus === 0 ? "Draft" : state.scorecardStatus === 1 ? "Active" : "Inactive"}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
};

export default ScorecardDetailsModal;
