import {
    SET_PROFILE,
    SET_USERDETAIL,
    SET_GLOBAL_FILTER_NAMES,
    SET_GLOBAL_FILTER_LIST,
    SET_USER_ROLES,
    SET_WHITELABELING_RESPONE,
    SET_ADMIN_FLAG
} from "./action";

const initialState = {
    items: [],
    profile: null,
    loading: false,
    error: null,
    userdetailObject: {},
    whiteLabeling: {},
    filterNames: [],
    filterList: [],
    adminFlag :false,
};
export default function urlReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_WHITELABELING_RESPONE:
            return {
                ...state,
                whiteLabeling: action.payload.response
            };
        case SET_USER_ROLES:
            return {
                ...state,
                items: action.payload.roles
            };
        case SET_GLOBAL_FILTER_LIST:
            return {
                ...state,
                filterList: action.payload.filter
            };
        case SET_GLOBAL_FILTER_NAMES:
            return {
                ...state,
                filterNames: action.payload.filterNames
            };
        case SET_USERDETAIL:
            return {
                ...state,
                userdetailObject: action.payload.object
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload.profile
            };
        case SET_ADMIN_FLAG:
            return {
                ...state,
                adminFlag: action.payload.adminFlag
            };
        default:
            return state;
    }
}

