import React, {Component, Fragment} from 'react';
import {Button, Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Highcharts from 'highcharts';
import {getScorecardMetricDetails, getScorecardMetricDetailsFilter} from "../Services/MetricsService";
import {formateDate, warningToast} from "../Components/ToastMessage";
import Exporting from "highcharts/modules/exporting";
import BlockLoader from "../Components/Loader/BlockLoader";

Exporting(Highcharts);

export default class MetricDetailsModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            metricName: '',
            ownerMappings: [],
            customFields: []
        }
    }

    setDataIntoTable = (data) => {
        let queryData = data.queryData;
        let tableData= [];
        let metricTrendMappings = [];
        for(let i in queryData){
            if(queryData.hasOwnProperty(i)){
                if(parseInt(i) === 0) {
                    let obj= [];
                    let objectKeys = Object.keys(queryData[i]).map((key) => {
                        return key
                    });
                    for(let j=0; j<=10; j++){
                        if(objectKeys.hasOwnProperty(j)){
                            obj.push(objectKeys[j])
                        }else {
                            obj.push("")
                        }
                    }
                    this.setState({
                        series1: obj[1],
                        series2: obj[2],
                        series3: obj[3],
                        series4: obj[4],
                        series5: obj[5],
                        series6: obj[6],
                        series7: obj[7],
                        series8: obj[8],
                        series9: obj[9],
                        series10: obj[10],
                    });
                    tableData.push(obj)
                }
                let temp = tableData[0];
                let object = [];
                for(let j=0; j<=10; j++){
                    if(temp.hasOwnProperty(j)){
                        object.push(queryData[i][temp[j]])
                    } else {
                        object.push("")
                    }
                }

                let obj = {};

                if(object[0]){
                    obj.columnName = object[0];
                }
                obj.columnValue = object[1];
                obj.columnValue1 = object[2];
                obj.columnValue3 = object[3];
                obj.columnValue4 = object[4];
                obj.columnValue5 = object[5];
                obj.columnValue6 = object[6];
                obj.columnValue7 = object[7];
                obj.columnValue8 = object[8];
                obj.columnValue9 = object[9];
                obj.columnValue10 = object[10];

                metricTrendMappings.push(obj);
                tableData.push(object)
            }
        }
        return metricTrendMappings;
    };


    loadChart = (id) => {
        this.setState({
            loading: true
        });

        if(this.props.filterList && this.props.filterList.length > 0){
            getScorecardMetricDetailsFilter(id, this.props.filterList)
                .then(response => {
                    if(response.status) {
                        let data = response.data;

                        this.setState({
                            ...data,
                            loading: false
                        });
                        if (response.data.scorecardDomainsMapping && response.data.scorecardDomainsMapping.scorecardDomains.domainName !== null) {
                            this.setState({
                                domainName: response.data.scorecardDomainsMapping.scorecardDomains.domainName
                            })
                        }
                        if (response.data.scorecardSubDomainsMapping && response.data.scorecardSubDomainsMapping.subDomainName !== null) {
                            this.setState({
                                subDomainName: response.data.scorecardSubDomainsMapping.subDomainName
                            })
                        }


                        let updatedDate = new Date(data.updatedOn);
                        updatedDate.toString();

                        if (data.trendStatus) {
                            let series1 = data.metricTrend.series1;
                            let series2 = data.metricTrend.series2;
                            let series3 = data.metricTrend.series3;
                            let series4 = data.metricTrend.series4;
                            let series5 = data.metricTrend.series5;
                            let series6 = data.metricTrend.series6;
                            let series7 = data.metricTrend.series7;
                            let series8 = data.metricTrend.series8;
                            let series9 = data.metricTrend.series9;
                            let series10 = data.metricTrend.series10;

                            let seriesType1 = "line";
                            let seriesType2 = "line";
                            let seriesType3 = "line";
                            let seriesType4 = "line";
                            let seriesType5 = "line";
                            let seriesType6 = "line";
                            let seriesType7 = "line";
                            let seriesType8 = "line";
                            let seriesType9 = "line";
                            let seriesType10 = "line";
                            let seriesTypeStyle1 = "";
                            let seriesTypeStyle2 = "";
                            let seriesTypeStyle3 = "";
                            let seriesTypeStyle4 = "";
                            let seriesTypeStyle5 = "";
                            let seriesTypeStyle6 = "";
                            let seriesTypeStyle7 = "";
                            let seriesTypeStyle8 = "";
                            let seriesTypeStyle9 = "";
                            let seriesTypeStyle10 = "";

                            if (data.metricTrend.series1Type === 2) {
                                seriesType1 = "column";
                            } else if (data.metricTrend.series1Type === 3) {
                                seriesType1 = "area";
                            } else if (data.metricTrend.series1Type === 4) {
                                seriesTypeStyle1 = "longdash";
                            } else {
                                seriesType1 = "line";
                            }
                            if (data.metricTrend.series2Type === 2) {
                                seriesType2 = "column";
                            } else if (data.metricTrend.series2Type === 3) {
                                seriesType2 = "area";
                            } else if (data.metricTrend.series2Type === 4) {
                                seriesTypeStyle2 = "longdash";
                            } else {
                                seriesType2 = "line";
                            }
                            if (data.metricTrend.series3Type === 2) {
                                seriesType3 = "column";
                            } else if (data.metricTrend.series3Type === 3) {
                                seriesType3 = "area";
                            } else if (data.metricTrend.series3Type === 4) {
                                seriesTypeStyle3 = "longdash";
                            } else {
                                seriesType3 = "line";
                            }
                            if (data.metricTrend.series4Type === 2) {
                                seriesType4 = "column";
                            } else if (data.metricTrend.series4Type === 3) {
                                seriesType4 = "area";
                            } else if (data.metricTrend.series4Type === 4) {
                                seriesTypeStyle4 = "longdash";
                            } else {
                                seriesType4 = "line";
                            }
                            if (data.metricTrend.series5Type === 2) {
                                seriesType5 = "column";
                            } else if (data.metricTrend.series5Type === 3) {
                                seriesType5 = "area";
                            } else if (data.metricTrend.series5Type === 4) {
                                seriesTypeStyle5 = "longdash";
                            } else {
                                seriesType5 = "line";
                            }
                            if (data.metricTrend.series6Type === 2) {
                                seriesType6 = "column";
                            } else if (data.metricTrend.series6Type === 3) {
                                seriesType6 = "area";
                            } else if (data.metricTrend.series6Type === 4) {
                                seriesTypeStyle6 = "longdash";
                            } else {
                                seriesType6 = "line";
                            }
                            if (data.metricTrend.series7Type === 2) {
                                seriesType7 = "column";
                            } else if (data.metricTrend.series7Type === 3) {
                                seriesType7 = "area";
                            } else if (data.metricTrend.series7Type === 4) {
                                seriesTypeStyle7 = "longdash";
                            } else {
                                seriesType7 = "line";
                            }
                            if (data.metricTrend.series8Type === 2) {
                                seriesType8 = "column";
                            } else if (data.metricTrend.series8Type === 3) {
                                seriesType8 = "area";
                            } else if (data.metricTrend.series8Type === 4) {
                                seriesTypeStyle8 = "longdash";
                            } else {
                                seriesType8 = "line";
                            }
                            if (data.metricTrend.series9Type === 2) {
                                seriesType9 = "column";
                            } else if (data.metricTrend.series9Type === 3) {
                                seriesType9 = "area";
                            } else if (data.metricTrend.series9Type === 4) {
                                seriesTypeStyle9 = "longdash";
                            } else {
                                seriesType9 = "line";
                            }
                            if (data.metricTrend.series10Type === 2) {
                                seriesType10 = "column";
                            } else if (data.metricTrend.series10Type === 3) {
                                seriesType10 = "area";
                            } else if (data.metricTrend.series10Type === 4) {
                                seriesTypeStyle10 = "longdash";
                            } else {
                                seriesType10 = "line";
                            }

                            let seriesColor1 = "#" + data.metricTrend.series1Color;
                            let seriesColor2 = "#" + data.metricTrend.series2Color;
                            let seriesColor3 = "#" + data.metricTrend.series3Color;
                            let seriesColor4 = "#" + data.metricTrend.series4Color;
                            let seriesColor5 = "#" + data.metricTrend.series5Color;
                            let seriesColor6 = "#" + data.metricTrend.series6Color;
                            let seriesColor7 = "#" + data.metricTrend.series7Color;
                            let seriesColor8 = "#" + data.metricTrend.series8Color;
                            let seriesColor9 = "#" + data.metricTrend.series9Color;
                            let seriesColor10 = "#" + data.metricTrend.series10Color;

                            let trendColumnName = [];
                            let trendColumnValue = [];
                            let trendColumnValue1 = [];
                            let trendColumnValue2 = [];
                            let trendColumnValue3 = [];
                            let trendColumnValue4 = [];
                            let trendColumnValue5 = [];
                            let trendColumnValue6 = [];
                            let trendColumnValue7 = [];
                            let trendColumnValue8 = [];
                            let trendColumnValue9 = [];


                            let trendMappings = data.metricTrend.metricTrendMappings;
                            if (trendMappings !== null) {

                                let startTrendData = 0;
                                let endTrendData = trendMappings.length;

                                if (data.metricTrend.rowLimitType !== null && data.metricTrend.rowLimitType !== '' && data.metricTrend.rowLimitType === 1) {
                                    if (data.metricTrend.rowLimit !== null && data.metricTrend.rowLimit !== '') {
                                        startTrendData = endTrendData - data.metricTrend.rowLimit;

                                    }
                                } else {
                                    if (data.metricTrend.rowLimit !== null && data.metricTrend.rowLimit !== '') {
                                        endTrendData = data.metricTrend.rowLimit;

                                    }
                                }

                                for (let k = startTrendData; k < endTrendData; k++) {

                                    if (trendMappings[k].columnName !== null) {
                                        trendColumnName.push(trendMappings[k].columnName);
                                        if (trendMappings[k].columnValue !== null) {
                                            trendColumnValue.push(trendMappings[k].columnValue);
                                        } else {
                                            trendColumnValue.push(null);
                                        }
                                        if (trendMappings[k].columnValue1 !== null) {
                                            trendColumnValue1.push(trendMappings[k].columnValue1);
                                        } else {
                                            trendColumnValue1.push(null);
                                        }
                                        if (trendMappings[k].columnValue2 !== null) {
                                            trendColumnValue2.push(trendMappings[k].columnValue2);
                                        } else {
                                            trendColumnValue2.push(null);
                                        }
                                        if (trendMappings[k].columnValue3 !== null) {
                                            trendColumnValue3.push(trendMappings[k].columnValue3);
                                        } else {
                                            trendColumnValue3.push(null);
                                        }
                                        if (trendMappings[k].columnValue4 !== null) {
                                            trendColumnValue4.push(trendMappings[k].columnValue4);
                                        } else {
                                            trendColumnValue4.push(null);
                                        }
                                        if (trendMappings[k].columnValue5 !== null) {
                                            trendColumnValue5.push(trendMappings[k].columnValue5);
                                        } else {
                                            trendColumnValue5.push(null);
                                        }
                                        if (trendMappings[k].columnValue6 !== null) {
                                            trendColumnValue6.push(trendMappings[k].columnValue6);
                                        } else {
                                            trendColumnValue6.push(null);
                                        }
                                        if (trendMappings[k].columnValue7 !== null) {
                                            trendColumnValue7.push(trendMappings[k].columnValue7);
                                        } else {
                                            trendColumnValue7.push(null);
                                        }
                                        if (trendMappings[k].columnValue8 !== null) {
                                            trendColumnValue8.push(trendMappings[k].columnValue8);
                                        } else {
                                            trendColumnValue8.push(null);
                                        }
                                        if (trendMappings[k].columnValue9 !== null) {
                                            trendColumnValue9.push(trendMappings[k].columnValue9);
                                        } else {
                                            trendColumnValue9.push(null);
                                        }
                                    }


                                }
                            }

                            let xAxisTitle = '';
                            let yAxisTitle = '';
                            if (data.metricTrend.xTitle !== null) {
                                xAxisTitle = data.metricTrend.xTitle;
                            }
                            if (data.metricTrend.yTitle !== null) {
                                yAxisTitle = data.metricTrend.yTitle;
                            }

                            let graphSeries = [];
                            let valuePrefix1 = null;
                            let valueSuffix1 = null;


                            let series1HideStatus = true;
                            let series2HideStatus = true;
                            let series3HideStatus = true;
                            let series4HideStatus = true;
                            let series5HideStatus = true;
                            let series6HideStatus = true;
                            let series7HideStatus = true;
                            let series8HideStatus = true;
                            let series9HideStatus = true;
                            let series10HideStatus = true;


                            if (data.metricTrend.series1ValueType !== null && data.metricTrend.series1ValueType === 2) {
                                valuePrefix1 = '$';
                            } else if (data.metricTrend.series1ValueType !== null && data.metricTrend.series1ValueType === 3) {
                                valueSuffix1 = '%';
                            } else if (data.metricTrend.series1ValueType !== null && data.metricTrend.series1ValueType === 4) {
                                series1HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix2 = null;
                            let valueSuffix2 = null;
                            if (data.metricTrend.series2ValueType !== null && data.metricTrend.series2ValueType === 2) {
                                valuePrefix2 = '$';
                            } else if (data.metricTrend.series2ValueType !== null && data.metricTrend.series2ValueType === 3) {
                                valueSuffix2 = '%';
                            } else if (data.metricTrend.series2ValueType !== null && data.metricTrend.series2ValueType === 4) {
                                series2HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix3 = null;
                            let valueSuffix3 = null;
                            if (data.metricTrend.series3ValueType !== null && data.metricTrend.series3ValueType === 2) {
                                valuePrefix3 = '$';
                            } else if (data.metricTrend.series3ValueType !== null && data.metricTrend.series3ValueType === 3) {
                                valueSuffix3 = '%';
                            } else if (data.metricTrend.series3ValueType !== null && data.metricTrend.series3ValueType === 4) {
                                series3HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix4 = null;
                            let valueSuffix4 = null;
                            if (data.metricTrend.series4ValueType !== null && data.metricTrend.series4ValueType === 2) {
                                valuePrefix4 = '$';
                            } else if (data.metricTrend.series4ValueType !== null && data.metricTrend.series4ValueType === 3) {
                                valueSuffix4 = '%';
                            } else if (data.metricTrend.series4ValueType !== null && data.metricTrend.series4ValueType === 4) {
                                series4HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix5 = null;
                            let valueSuffix5 = null;
                            if (data.metricTrend.series5ValueType !== null && data.metricTrend.series5ValueType === 2) {
                                valuePrefix5 = '$';
                            } else if (data.metricTrend.series5ValueType !== null && data.metricTrend.series5ValueType === 3) {
                                valueSuffix5 = '%';
                            } else if (data.metricTrend.series5ValueType !== null && data.metricTrend.series5ValueType === 4) {
                                series5HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix6 = null;
                            let valueSuffix6 = null;
                            if (data.metricTrend.series6ValueType !== null && data.metricTrend.series6ValueType === 2) {
                                valuePrefix6 = '$';
                            } else if (data.metricTrend.series6ValueType !== null && data.metricTrend.series6ValueType === 3) {
                                valueSuffix6 = '%';
                            } else if (data.metricTrend.series6ValueType !== null && data.metricTrend.series6ValueType === 4) {
                                series6HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix7 = null;
                            let valueSuffix7 = null;
                            if (data.metricTrend.series7ValueType !== null && data.metricTrend.series7ValueType === 2) {
                                valuePrefix7 = '$';
                            } else if (data.metricTrend.series7ValueType !== null && data.metricTrend.series7ValueType === 3) {
                                valueSuffix7 = '%';
                            } else if (data.metricTrend.series7ValueType !== null && data.metricTrend.series7ValueType === 4) {
                                series7HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix8 = null;
                            let valueSuffix8 = null;
                            if (data.metricTrend.series8ValueType !== null && data.metricTrend.series8ValueType === 2) {
                                valuePrefix8 = '$';
                            } else if (data.metricTrend.series8ValueType !== null && data.metricTrend.series8ValueType === 3) {
                                valueSuffix8 = '%';
                            } else if (data.metricTrend.series8ValueType !== null && data.metricTrend.series8ValueType === 4) {
                                series8HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix9 = null;
                            let valueSuffix9 = null;
                            if (data.metricTrend.series9ValueType !== null && data.metricTrend.series9ValueType === 2) {
                                valuePrefix9 = '$';
                            } else if (data.metricTrend.series9ValueType !== null && data.metricTrend.series9ValueType === 3) {
                                valueSuffix9 = '%';
                            } else if (data.metricTrend.series9ValueType !== null && data.metricTrend.series9ValueType === 4) {
                                series9HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix10 = null;
                            let valueSuffix10 = null;
                            if (data.metricTrend.series10ValueType !== null && data.metricTrend.series10ValueType === 2) {
                                valuePrefix10 = '$';
                            } else if (data.metricTrend.series10ValueType !== null && data.metricTrend.series10ValueType === 3) {
                                valueSuffix10 = '%';
                            } else if (data.metricTrend.series10ValueType !== null && data.metricTrend.series10ValueType === 4) {
                                series10HideStatus = false;
                            } else {
                                //						nothing
                            }





                            let tooltip1 = {
                                "valuePrefix": valuePrefix1,
                                "valueSuffix": valueSuffix1
                            }
                            let tooltip2 = {
                                "valuePrefix": valuePrefix2,
                                "valueSuffix": valueSuffix2
                            }
                            let tooltip3 = {
                                "valuePrefix": valuePrefix3,
                                "valueSuffix": valueSuffix3
                            }
                            let tooltip4 = {
                                "valuePrefix": valuePrefix4,
                                "valueSuffix": valueSuffix4
                            }
                            let tooltip5 = {
                                "valuePrefix": valuePrefix5,
                                "valueSuffix": valueSuffix5
                            }
                            let tooltip6 = {
                                "valuePrefix": valuePrefix6,
                                "valueSuffix": valueSuffix6
                            }
                            let tooltip7 = {
                                "valuePrefix": valuePrefix7,
                                "valueSuffix": valueSuffix7
                            }
                            let tooltip8 = {
                                "valuePrefix": valuePrefix8,
                                "valueSuffix": valueSuffix8
                            }
                            let tooltip9 = {
                                "valuePrefix": valuePrefix9,
                                "valueSuffix": valueSuffix9
                            }
                            let tooltip10 = {
                                "valuePrefix": valuePrefix10,
                                "valueSuffix": valueSuffix10
                            }
                            let graphSeries1 = {
                                "type": seriesType1,
                                "data": trendColumnValue,
                                "pointStart": 0,
                                "name": series1,
                                "color": seriesColor1,
                                "dashStyle": seriesTypeStyle1,
                                "tooltip": tooltip1
                            }
                            let graphSeries2 = {
                                "type": seriesType2,
                                "data": trendColumnValue1,
                                "pointStart": 0,
                                "name": series2,
                                "color": seriesColor2,
                                "dashStyle": seriesTypeStyle2,
                                "tooltip": tooltip2
                            }
                            let graphSeries3 = {
                                "type": seriesType3,
                                "data": trendColumnValue2,
                                "pointStart": 0,
                                "name": series3,
                                "color": seriesColor3,
                                "dashStyle": seriesTypeStyle3,
                                "tooltip": tooltip3
                            }
                            let graphSeries4 = {
                                "type": seriesType4,
                                "data": trendColumnValue3,
                                "pointStart": 0,
                                "name": series4,
                                "color": seriesColor4,
                                "dashStyle": seriesTypeStyle4,
                                "tooltip": tooltip4
                            }
                            let graphSeries5 = {
                                "type": seriesType5,
                                "data": trendColumnValue4,
                                "pointStart": 0,
                                "name": series5,
                                "color": seriesColor5,
                                "dashStyle": seriesTypeStyle5,
                                "tooltip": tooltip5
                            }
                            let graphSeries6 = {
                                "type": seriesType6,
                                "data": trendColumnValue5,
                                "pointStart": 0,
                                "name": series6,
                                "color": seriesColor6,
                                "dashStyle": seriesTypeStyle6,
                                "tooltip": tooltip6
                            }
                            let graphSeries7 = {
                                "type": seriesType7,
                                "data": trendColumnValue6,
                                "pointStart": 0,
                                "name": series7,
                                "color": seriesColor7,
                                "dashStyle": seriesTypeStyle7,
                                "tooltip": tooltip7
                            }
                            let graphSeries8 = {
                                "type": seriesType8,
                                "data": trendColumnValue7,
                                "pointStart": 0,
                                "name": series8,
                                "color": seriesColor8,
                                "dashStyle": seriesTypeStyle8,
                                "tooltip": tooltip8
                            }
                            let graphSeries9 = {
                                "type": seriesType9,
                                "data": trendColumnValue8,
                                "pointStart": 0,
                                "name": series9,
                                "color": seriesColor9,
                                "dashStyle": seriesTypeStyle9,
                                "tooltip": tooltip9
                            }
                            let graphSeries10 = {
                                "type": seriesType10,
                                "data": trendColumnValue9,
                                "pointStart": 0,
                                "name": series10,
                                "color": seriesColor10,
                                "dashStyle": seriesTypeStyle10,
                                "tooltip": tooltip10
                            }
                            let graphSeriesStatus1 = false;
                            for (let c1 = 0; c1 < trendColumnValue.length; c1++) {
                                if (trendColumnValue[c1] !== null && trendColumnValue[c1] !== '') {
                                    graphSeriesStatus1 = true;
                                }
                            }
                            let graphSeriesStatus2 = false;
                            for (let c2 = 0; c2 < trendColumnValue1.length; c2++) {
                                if (trendColumnValue1[c2] !== null && trendColumnValue1[c2] !== '') {
                                    graphSeriesStatus2 = true;
                                }
                            }
                            let graphSeriesStatus3 = false;
                            for (let c3 = 0; c3 < trendColumnValue2.length; c3++) {
                                if (trendColumnValue2[c3] !== null && trendColumnValue2[c3] !== '') {
                                    graphSeriesStatus3 = true;
                                }
                            }
                            let graphSeriesStatus4 = false;
                            for (let c4 = 0; c4 < trendColumnValue3.length; c4++) {
                                if (trendColumnValue3[c4] !== null && trendColumnValue3[c4] !== '') {
                                    graphSeriesStatus4 = true;
                                }
                            }
                            let graphSeriesStatus5 = false;
                            for (let c5 = 0; c5 < trendColumnValue4.length; c5++) {
                                if (trendColumnValue4[c5] !== null && trendColumnValue4[c5] !== '') {
                                    graphSeriesStatus5 = true;
                                }
                            }
                            let graphSeriesStatus6 = false;
                            for (let c6 = 0; c6 < trendColumnValue5.length; c6++) {
                                if (trendColumnValue5[c6] !== null && trendColumnValue5[c6] !== '') {
                                    graphSeriesStatus6 = true;
                                }
                            }
                            let graphSeriesStatus7 = false;
                            for (let c7 = 0; c7 < trendColumnValue6.length; c7++) {
                                if (trendColumnValue6[c7] !== null && trendColumnValue6[c7] !== '') {
                                    graphSeriesStatus7 = true;
                                }
                            }
                            let graphSeriesStatus8 = false;
                            for (let c8 = 0; c8 < trendColumnValue7.length; c8++) {
                                if (trendColumnValue7[c8] !== null && trendColumnValue7[c8] !== '') {
                                    graphSeriesStatus8 = true;
                                }
                            }
                            let graphSeriesStatus9 = false;
                            for (let c9 = 0; c9 < trendColumnValue8.length; c9++) {
                                if (trendColumnValue8[c9] !== null && trendColumnValue8[c9] !== '') {
                                    graphSeriesStatus9 = true;
                                }
                            }
                            let graphSeriesStatus10 = false;
                            for (let c10 = 0; c10 < trendColumnValue9.length; c10++) {
                                if (trendColumnValue9[c10] !== null && trendColumnValue9[c10] !== '') {
                                    graphSeriesStatus10 = true;
                                }
                            }
                            if (graphSeriesStatus1 && series1HideStatus) {
                                graphSeries.push(graphSeries1);
                            }
                            if (graphSeriesStatus2 && series2HideStatus) {
                                graphSeries.push(graphSeries2);
                            }
                            if (graphSeriesStatus3 && series3HideStatus) {
                                graphSeries.push(graphSeries3);
                            }
                            if (graphSeriesStatus4 && series4HideStatus) {
                                graphSeries.push(graphSeries4);
                            }
                            if (graphSeriesStatus5 && series5HideStatus) {
                                graphSeries.push(graphSeries5);
                            }
                            if (graphSeriesStatus6 && series6HideStatus) {
                                graphSeries.push(graphSeries6);
                            }
                            if (graphSeriesStatus7 && series7HideStatus) {
                                graphSeries.push(graphSeries7);
                            }
                            if (graphSeriesStatus8 && series8HideStatus) {
                                graphSeries.push(graphSeries8);
                            }
                            if (graphSeriesStatus9 && series9HideStatus) {
                                graphSeries.push(graphSeries9);
                            }
                            if (graphSeriesStatus10 && series10HideStatus) {
                                graphSeries.push(graphSeries10);
                            }

                            Highcharts.chart('sideDivMetricGraphDesign', {
                                title: {
                                    text: ''
                                },

                                xAxis: {
                                    gridLineWidth: 0,
                                    tickInterval: 1,
                                    title: {
                                        text: xAxisTitle,
                                        //align: 'center'
                                    },
                                    categories: trendColumnName
                                },
                                yAxis: {

                                    minorTickInterval: 0,
                                    title: {
                                        text: yAxisTitle,
                                    }
                                },
                                plotOptions: {
                                    series: {
                                        label: {
                                            connectorAllowed: false
                                        },
                                    }
                                },
                                credits: {
                                    enabled: false
                                },
                                series: graphSeries,
                                tooltip: {
                                    // formatter: function () {
                                    //     // The first returned item is the header, subsequent items are the
                                    //     // points
                                    //     return (this.points.map(function (point) {
                                    //             return point.series.name + ': ' + point.y + 'm';
                                    //         })
                                    //     );
                                    // },

                                    formatter: function () {
                                        let pointNum = parseFloat(this.y);
                                        let textValue = pointNum.toLocaleString();
                                        if (this.series && this.series.tooltipOptions.valuePrefix !== null && this.series.tooltipOptions.valuePrefix !== '') {
                                            return this.x + '<br>' + this.series.name + '<br>' + this.series.tooltipOptions.valuePrefix + ' ' + textValue;
                                        } else if (this.series && this.series.tooltipOptions.valueSuffix !== null && this.series.tooltipOptions.valueSuffix !== '') {
                                            return this.x + '<br>' + this.series.name + '<br>' + textValue + ' ' + this.series.tooltipOptions.valueSuffix;
                                        } else {
                                            return this.x + '<br>' + this.series.name + '<br>' + textValue;
                                        }
                                    },
                                },
                                exporting: {
                                    enabled: true
                                },
                            });
                        }

                        // }
                    }else {
                        warningToast(response['tempMassage']);
                    }

                })
        } else {
            getScorecardMetricDetails(id)
                .then(response => {
                    if(response.status) {
                        let data = response.data;
                        // if (data.metricTrendDataReportId) {
                        //     fetchReportById(data.metricTrendDataReportId)
                        //         .then(response2 => {
                        //             let metricTrendMapping = this.setDataIntoTable(response2.data);
                        //             console.log("metricTrendMapping : ", metricTrendMapping)
                        //             this.setState({
                        //                 ...data,
                        //                 loading: false
                        //             });
                        //             if (response.data.scorecardDomainsMapping && response.data.scorecardDomainsMapping.scorecardDomains.domainName !== null) {
                        //                 this.setState({
                        //                     domainName: response.data.scorecardDomainsMapping.scorecardDomains.domainName
                        //                 })
                        //             }
                        //             if (response.data.scorecardSubDomainsMapping && response.data.scorecardSubDomainsMapping.subDomainName !== null) {
                        //                 this.setState({
                        //                     subDomainName: response.data.scorecardSubDomainsMapping.subDomainName
                        //                 })
                        //             }
                        //
                        //
                        //             for (let j = 0; j < data.customFields.length; j++) {
                        //                 if (data.customFields[j].displayStatus) {
                        //
                        //                     if (data.customFields[j].columnValue != null && data.customFields[j].columnValue !== '') {
                        //                         if (data.customFields[j].columnType === 4) {
                        //
                        //                         } else if (data.customFields[j].columnType === 3) {
                        //                             let textValue1 = data.customFields[j].columnValue;
                        //                             let pointNum = parseFloat(textValue1);
                        //                             let textValue = pointNum.toLocaleString();
                        //
                        //
                        //                         } else if (data.customFields[j].columnType === 2) {
                        //                             let textValue1 = data.customFields[j].columnValue;
                        //                             let pointNum = parseFloat(textValue1);
                        //                             let textValue = pointNum.toLocaleString();
                        //
                        //                         } else if (data.customFields[j].columnType === 1) {
                        //                             let textValue1 = data.customFields[j].columnValue;
                        //                             let pointNum = parseFloat(textValue1);
                        //                             let textValue = pointNum.toLocaleString();
                        //
                        //                         }
                        //                     }
                        //                 }
                        //             }
                        //
                        //             for (let j = 0; j < data.customFields.length; j++) {
                        //                 if (!data.customFields[j].displayStatus) {
                        //
                        //                     if (data.customFields[j].columnValue != null && data.customFields[j].columnValue != '') {
                        //                         if (data.customFields[j].columnType == 4) {
                        //
                        //                         } else if (data.customFields[j].columnType == 3) {
                        //                             let textValue1 = data.customFields[j].columnValue;
                        //                             let pointNum = parseFloat(textValue1);
                        //                             let textValue = pointNum.toLocaleString();
                        //
                        //                         } else if (data.customFields[j].columnType == 2) {
                        //                             let textValue1 = data.customFields[j].columnValue;
                        //                             let pointNum = parseFloat(textValue1);
                        //                             let textValue = pointNum.toLocaleString();
                        //
                        //                         } else if (data.customFields[j].columnType == 1) {
                        //                             let textValue1 = data.customFields[j].columnValue;
                        //                             let pointNum = parseFloat(textValue1);
                        //                             let textValue = pointNum.toLocaleString();
                        //
                        //                         }
                        //                     }
                        //                 }
                        //             }
                        //
                        //
                        //             let updatedDate = new Date(data.updatedOn);
                        //             updatedDate.toString();
                        //             let updatedDate_Date = updatedDate.getDate();
                        //             let updatedDate_Month = updatedDate.getMonth();
                        //             let updatedDate_Year = updatedDate.getFullYear();
                        //             let time = updatedDate;
                        //             time = time.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
                        //             updatedDate_Month++;
                        //
                        //             if (updatedDate_Month < 10) {
                        //                 updatedDate_Month = '0' + updatedDate_Month;
                        //             }
                        //             if (updatedDate_Date < 10) {
                        //                 updatedDate_Date = '0' + updatedDate_Date;
                        //             }
                        //
                        //             let updatedDateFormat = "  " + updatedDate_Month + "/" + updatedDate_Date + "/" + updatedDate_Year + "   " + time;
                        //
                        //             if (data.trendStatus) {
                        //                 let series1 = data.metricTrend.series1;
                        //                 let series2 = data.metricTrend.series2;
                        //                 let series3 = data.metricTrend.series3;
                        //                 let series4 = data.metricTrend.series4;
                        //                 let series5 = data.metricTrend.series5;
                        //                 let series6 = data.metricTrend.series6;
                        //                 let series7 = data.metricTrend.series7;
                        //                 let series8 = data.metricTrend.series8;
                        //                 let series9 = data.metricTrend.series9;
                        //                 let series10 = data.metricTrend.series10;
                        //
                        //                 let seriesType1 = "line";
                        //                 let seriesType2 = "line";
                        //                 let seriesType3 = "line";
                        //                 let seriesType4 = "line";
                        //                 let seriesType5 = "line";
                        //                 let seriesType6 = "line";
                        //                 let seriesType7 = "line";
                        //                 let seriesType8 = "line";
                        //                 let seriesType9 = "line";
                        //                 let seriesType10 = "line";
                        //                 let seriesTypeStyle1 = "";
                        //                 let seriesTypeStyle2 = "";
                        //                 let seriesTypeStyle3 = "";
                        //                 let seriesTypeStyle4 = "";
                        //                 let seriesTypeStyle5 = "";
                        //                 let seriesTypeStyle6 = "";
                        //                 let seriesTypeStyle7 = "";
                        //                 let seriesTypeStyle8 = "";
                        //                 let seriesTypeStyle9 = "";
                        //                 let seriesTypeStyle10 = "";
                        //
                        //                 if (data.metricTrend.series1Type === 2) {
                        //                     seriesType1 = "column";
                        //                 } else if (data.metricTrend.series1Type === 3) {
                        //                     seriesType1 = "area";
                        //                 } else if (data.metricTrend.series1Type === 4) {
                        //                     seriesTypeStyle1 = "longdash";
                        //                 } else {
                        //                     seriesType1 = "line";
                        //                 }
                        //                 if (data.metricTrend.series2Type === 2) {
                        //                     seriesType2 = "column";
                        //                 } else if (data.metricTrend.series2Type === 3) {
                        //                     seriesType2 = "area";
                        //                 } else if (data.metricTrend.series2Type === 4) {
                        //                     seriesTypeStyle2 = "longdash";
                        //                 } else {
                        //                     seriesType2 = "line";
                        //                 }
                        //                 if (data.metricTrend.series3Type === 2) {
                        //                     seriesType3 = "column";
                        //                 } else if (data.metricTrend.series3Type === 3) {
                        //                     seriesType3 = "area";
                        //                 } else if (data.metricTrend.series3Type === 4) {
                        //                     seriesTypeStyle3 = "longdash";
                        //                 } else {
                        //                     seriesType3 = "line";
                        //                 }
                        //                 if (data.metricTrend.series4Type === 2) {
                        //                     seriesType4 = "column";
                        //                 } else if (data.metricTrend.series4Type == 3) {
                        //                     seriesType4 = "area";
                        //                 } else if (data.metricTrend.series4Type == 4) {
                        //                     seriesTypeStyle4 = "longdash";
                        //                 } else {
                        //                     seriesType4 = "line";
                        //                 }
                        //                 if (data.metricTrend.series5Type === 2) {
                        //                     seriesType5 = "column";
                        //                 } else if (data.metricTrend.series5Type == 3) {
                        //                     seriesType5 = "area";
                        //                 } else if (data.metricTrend.series5Type === 4) {
                        //                     seriesTypeStyle5 = "longdash";
                        //                 } else {
                        //                     seriesType5 = "line";
                        //                 }
                        //                 if (data.metricTrend.series6Type === 2) {
                        //                     seriesType6 = "column";
                        //                 } else if (data.metricTrend.series6Type == 3) {
                        //                     seriesType6 = "area";
                        //                 } else if (data.metricTrend.series6Type === 4) {
                        //                     seriesTypeStyle6 = "longdash";
                        //                 } else {
                        //                     seriesType6 = "line";
                        //                 }
                        //                 if (data.metricTrend.series7Type === 2) {
                        //                     seriesType7 = "column";
                        //                 } else if (data.metricTrend.series7Type == 3) {
                        //                     seriesType7 = "area";
                        //                 } else if (data.metricTrend.series7Type === 4) {
                        //                     seriesTypeStyle7 = "longdash";
                        //                 } else {
                        //                     seriesType7 = "line";
                        //                 }
                        //                 if (data.metricTrend.series8Type === 2) {
                        //                     seriesType8 = "column";
                        //                 } else if (data.metricTrend.series8Type == 3) {
                        //                     seriesType8 = "area";
                        //                 } else if (data.metricTrend.series8Type === 4) {
                        //                     seriesTypeStyle8 = "longdash";
                        //                 } else {
                        //                     seriesType8 = "line";
                        //                 }
                        //                 if (data.metricTrend.series9Type === 2) {
                        //                     seriesType9 = "column";
                        //                 } else if (data.metricTrend.series9Type == 3) {
                        //                     seriesType9 = "area";
                        //                 } else if (data.metricTrend.series9Type === 4) {
                        //                     seriesTypeStyle9 = "longdash";
                        //                 } else {
                        //                     seriesType9 = "line";
                        //                 }
                        //                 if (data.metricTrend.series10Type === 2) {
                        //                     seriesType10 = "column";
                        //                 } else if (data.metricTrend.series10Type == 3) {
                        //                     seriesType10 = "area";
                        //                 } else if (data.metricTrend.series10Type === 4) {
                        //                     seriesTypeStyle10 = "longdash";
                        //                 } else {
                        //                     seriesType10 = "line";
                        //                 }
                        //
                        //                 let seriesColor1 = "#" + data.metricTrend.series1Color;
                        //                 let seriesColor2 = "#" + data.metricTrend.series2Color;
                        //                 let seriesColor3 = "#" + data.metricTrend.series3Color;
                        //                 let seriesColor4 = "#" + data.metricTrend.series4Color;
                        //                 let seriesColor5 = "#" + data.metricTrend.series5Color;
                        //                 let seriesColor6 = "#" + data.metricTrend.series6Color;
                        //                 let seriesColor7 = "#" + data.metricTrend.series7Color;
                        //                 let seriesColor8 = "#" + data.metricTrend.series8Color;
                        //                 let seriesColor9 = "#" + data.metricTrend.series9Color;
                        //                 let seriesColor10 = "#" + data.metricTrend.series10Color;
                        //
                        //                 let seriesValueType1 = data.metricTrend.series1ValueType;
                        //                 let seriesValueType2 = data.metricTrend.series2ValueType;
                        //                 let seriesValueType3 = data.metricTrend.series3ValueType;
                        //                 let seriesValueType4 = data.metricTrend.series4ValueType;
                        //                 let seriesValueType5 = data.metricTrend.series5ValueType;
                        //                 let seriesValueType6 = data.metricTrend.series6ValueType;
                        //                 let seriesValueType7 = data.metricTrend.series7ValueType;
                        //                 let seriesValueType8 = data.metricTrend.series8ValueType;
                        //                 let seriesValueType9 = data.metricTrend.series9ValueType;
                        //                 let seriesValueType10 = data.metricTrend.series10ValueType;
                        //
                        //                 let trendColumnName = [];
                        //                 let trendColumnValue = [];
                        //                 let trendColumnValue1 = [];
                        //                 let trendColumnValue2 = [];
                        //                 let trendColumnValue3 = [];
                        //                 let trendColumnValue4 = [];
                        //                 let trendColumnValue5 = [];
                        //                 let trendColumnValue6 = [];
                        //                 let trendColumnValue7 = [];
                        //                 let trendColumnValue8 = [];
                        //                 let trendColumnValue9 = [];
                        //
                        //
                        //                 let trendMappings = data.metricTrend.metricTrendMappings;
                        //                 if (trendMappings != null) {
                        //
                        //                     let startTrendData = 0;
                        //                     let endTrendData = trendMappings.length;
                        //
                        //                     // if (data.metricTrend.rowLimitType != null && data.metricTrend.rowLimitType != '' && data.metricTrend.rowLimitType == 1) {
                        //                     //     if (data.metricTrend.rowLimit != null && data.metricTrend.rowLimit != '') {
                        //                     //         startTrendData = endTrendData - data.metricTrend.rowLimit;
                        //                     //
                        //                     //     }
                        //                     // } else {
                        //                     //     if (data.metricTrend.rowLimit != null && data.metricTrend.rowLimit != '') {
                        //                     //         endTrendData = data.metricTrend.rowLimit;
                        //                     //
                        //                     //     }
                        //                     // }
                        //
                        //                     for (let k = startTrendData; k < endTrendData; k++) {
                        //
                        //                         if (trendMappings[k].columnName != null) {
                        //                             trendColumnName.push(trendMappings[k].columnName);
                        //                             if (trendMappings[k].columnValue != null) {
                        //                                 trendColumnValue.push(trendMappings[k].columnValue);
                        //                             } else {
                        //                                 trendColumnValue.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue1 != null) {
                        //                                 trendColumnValue1.push(trendMappings[k].columnValue1);
                        //                             } else {
                        //                                 trendColumnValue1.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue2 != null) {
                        //                                 trendColumnValue2.push(trendMappings[k].columnValue2);
                        //                             } else {
                        //                                 trendColumnValue2.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue3 != null) {
                        //                                 trendColumnValue3.push(trendMappings[k].columnValue3);
                        //                             } else {
                        //                                 trendColumnValue3.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue4 != null) {
                        //                                 trendColumnValue4.push(trendMappings[k].columnValue4);
                        //                             } else {
                        //                                 trendColumnValue4.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue5 != null) {
                        //                                 trendColumnValue5.push(trendMappings[k].columnValue5);
                        //                             } else {
                        //                                 trendColumnValue5.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue6 != null) {
                        //                                 trendColumnValue6.push(trendMappings[k].columnValue6);
                        //                             } else {
                        //                                 trendColumnValue6.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue7 != null) {
                        //                                 trendColumnValue7.push(trendMappings[k].columnValue7);
                        //                             } else {
                        //                                 trendColumnValue7.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue8 != null) {
                        //                                 trendColumnValue8.push(trendMappings[k].columnValue8);
                        //                             } else {
                        //                                 trendColumnValue8.push(null);
                        //                             }
                        //                             if (trendMappings[k].columnValue9 != null) {
                        //                                 trendColumnValue9.push(trendMappings[k].columnValue9);
                        //                             } else {
                        //                                 trendColumnValue9.push(null);
                        //                             }
                        //                         }
                        //
                        //
                        //                     }
                        //                 }
                        //
                        //                 let xAxisTitle = '';
                        //                 let yAxisTitle = '';
                        //                 if (data.metricTrend.xTitle != null) {
                        //                     xAxisTitle = data.metricTrend.xTitle;
                        //                 }
                        //                 if (data.metricTrend.yTitle != null) {
                        //                     yAxisTitle = data.metricTrend.yTitle;
                        //                 }
                        //
                        //                 let graphSeries = [];
                        //                 let valuePrefix1 = null;
                        //                 let valueSuffix1 = null;
                        //                 if (data.metricTrend.series1ValueType != null && data.metricTrend.series1ValueType == 2) {
                        //                     valuePrefix1 = '$';
                        //                 } else if (data.metricTrend.series1ValueType != null && data.metricTrend.series1ValueType == 3) {
                        //                     valueSuffix1 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix2 = null;
                        //                 let valueSuffix2 = null;
                        //                 if (data.metricTrend.series2ValueType != null && data.metricTrend.series2ValueType == 2) {
                        //                     valuePrefix2 = '$';
                        //                 } else if (data.metricTrend.series2ValueType != null && data.metricTrend.series2ValueType == 3) {
                        //                     valueSuffix2 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix3 = null;
                        //                 let valueSuffix3 = null;
                        //                 if (data.metricTrend.series3ValueType != null && data.metricTrend.series3ValueType == 2) {
                        //                     valuePrefix3 = '$';
                        //                 } else if (data.metricTrend.series3ValueType != null && data.metricTrend.series3ValueType == 3) {
                        //                     valueSuffix3 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix4 = null;
                        //                 let valueSuffix4 = null;
                        //                 if (data.metricTrend.series4ValueType != null && data.metricTrend.series4ValueType == 2) {
                        //                     valuePrefix4 = '$';
                        //                 } else if (data.metricTrend.series4ValueType != null && data.metricTrend.series4ValueType == 3) {
                        //                     valueSuffix4 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix5 = null;
                        //                 let valueSuffix5 = null;
                        //                 if (data.metricTrend.series5ValueType != null && data.metricTrend.series5ValueType == 2) {
                        //                     valuePrefix5 = '$';
                        //                 } else if (data.metricTrend.series5ValueType != null && data.metricTrend.series5ValueType == 3) {
                        //                     valueSuffix5 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix6 = null;
                        //                 let valueSuffix6 = null;
                        //                 if (data.metricTrend.series6ValueType != null && data.metricTrend.series6ValueType == 2) {
                        //                     valuePrefix6 = '$';
                        //                 } else if (data.metricTrend.series6ValueType != null && data.metricTrend.series6ValueType == 3) {
                        //                     valueSuffix6 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix7 = null;
                        //                 let valueSuffix7 = null;
                        //                 if (data.metricTrend.series7ValueType != null && data.metricTrend.series7ValueType == 2) {
                        //                     valuePrefix7 = '$';
                        //                 } else if (data.metricTrend.series7ValueType != null && data.metricTrend.series7ValueType == 3) {
                        //                     valueSuffix7 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix8 = null;
                        //                 let valueSuffix8 = null;
                        //                 if (data.metricTrend.series8ValueType != null && data.metricTrend.series8ValueType == 2) {
                        //                     valuePrefix8 = '$';
                        //                 } else if (data.metricTrend.series8ValueType != null && data.metricTrend.series8ValueType == 3) {
                        //                     valueSuffix8 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix9 = null;
                        //                 let valueSuffix9 = null;
                        //                 if (data.metricTrend.series9ValueType != null && data.metricTrend.series9ValueType == 2) {
                        //                     valuePrefix9 = '$';
                        //                 } else if (data.metricTrend.series9ValueType != null && data.metricTrend.series9ValueType == 3) {
                        //                     valueSuffix9 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let valuePrefix10 = null;
                        //                 let valueSuffix10 = null;
                        //                 if (data.metricTrend.series10ValueType != null && data.metricTrend.series10ValueType == 2) {
                        //                     valuePrefix10 = '$';
                        //                 } else if (data.metricTrend.series10ValueType != null && data.metricTrend.series10ValueType == 3) {
                        //                     valueSuffix10 = '%';
                        //                 } else {
                        //                     //						nothing
                        //                 }
                        //
                        //                 let tooltip1 = {
                        //                     "valuePrefix": valuePrefix1,
                        //                     "valueSuffix": valueSuffix1
                        //                 }
                        //                 let tooltip2 = {
                        //                     "valuePrefix": valuePrefix2,
                        //                     "valueSuffix": valueSuffix2
                        //                 }
                        //                 let tooltip3 = {
                        //                     "valuePrefix": valuePrefix3,
                        //                     "valueSuffix": valueSuffix3
                        //                 }
                        //                 let tooltip4 = {
                        //                     "valuePrefix": valuePrefix4,
                        //                     "valueSuffix": valueSuffix4
                        //                 }
                        //                 let tooltip5 = {
                        //                     "valuePrefix": valuePrefix5,
                        //                     "valueSuffix": valueSuffix5
                        //                 }
                        //                 let tooltip6 = {
                        //                     "valuePrefix": valuePrefix6,
                        //                     "valueSuffix": valueSuffix6
                        //                 }
                        //                 let tooltip7 = {
                        //                     "valuePrefix": valuePrefix7,
                        //                     "valueSuffix": valueSuffix7
                        //                 }
                        //                 let tooltip8 = {
                        //                     "valuePrefix": valuePrefix8,
                        //                     "valueSuffix": valueSuffix8
                        //                 }
                        //                 let tooltip9 = {
                        //                     "valuePrefix": valuePrefix9,
                        //                     "valueSuffix": valueSuffix9
                        //                 }
                        //                 let tooltip10 = {
                        //                     "valuePrefix": valuePrefix10,
                        //                     "valueSuffix": valueSuffix10
                        //                 }
                        //                 let graphSeries1 = {
                        //                     "type": seriesType1,
                        //                     "data": trendColumnValue,
                        //                     "pointStart": 0,
                        //                     "name": series1,
                        //                     "color": seriesColor1,
                        //                     "dashStyle": seriesTypeStyle1,
                        //                     "tooltip": tooltip1
                        //                 }
                        //                 let graphSeries2 = {
                        //                     "type": seriesType2,
                        //                     "data": trendColumnValue1,
                        //                     "pointStart": 0,
                        //                     "name": series2,
                        //                     "color": seriesColor2,
                        //                     "dashStyle": seriesTypeStyle2,
                        //                     "tooltip": tooltip2
                        //                 }
                        //                 let graphSeries3 = {
                        //                     "type": seriesType3,
                        //                     "data": trendColumnValue2,
                        //                     "pointStart": 0,
                        //                     "name": series3,
                        //                     "color": seriesColor3,
                        //                     "dashStyle": seriesTypeStyle3,
                        //                     "tooltip": tooltip3
                        //                 }
                        //                 let graphSeries4 = {
                        //                     "type": seriesType4,
                        //                     "data": trendColumnValue3,
                        //                     "pointStart": 0,
                        //                     "name": series4,
                        //                     "color": seriesColor4,
                        //                     "dashStyle": seriesTypeStyle4,
                        //                     "tooltip": tooltip4
                        //                 }
                        //                 let graphSeries5 = {
                        //                     "type": seriesType5,
                        //                     "data": trendColumnValue4,
                        //                     "pointStart": 0,
                        //                     "name": series5,
                        //                     "color": seriesColor5,
                        //                     "dashStyle": seriesTypeStyle5,
                        //                     "tooltip": tooltip5
                        //                 }
                        //                 let graphSeries6 = {
                        //                     "type": seriesType6,
                        //                     "data": trendColumnValue5,
                        //                     "pointStart": 0,
                        //                     "name": series6,
                        //                     "color": seriesColor6,
                        //                     "dashStyle": seriesTypeStyle6,
                        //                     "tooltip": tooltip6
                        //                 }
                        //                 let graphSeries7 = {
                        //                     "type": seriesType7,
                        //                     "data": trendColumnValue6,
                        //                     "pointStart": 0,
                        //                     "name": series7,
                        //                     "color": seriesColor7,
                        //                     "dashStyle": seriesTypeStyle7,
                        //                     "tooltip": tooltip7
                        //                 }
                        //                 let graphSeries8 = {
                        //                     "type": seriesType8,
                        //                     "data": trendColumnValue7,
                        //                     "pointStart": 0,
                        //                     "name": series8,
                        //                     "color": seriesColor8,
                        //                     "dashStyle": seriesTypeStyle8,
                        //                     "tooltip": tooltip8
                        //                 }
                        //                 let graphSeries9 = {
                        //                     "type": seriesType9,
                        //                     "data": trendColumnValue8,
                        //                     "pointStart": 0,
                        //                     "name": series9,
                        //                     "color": seriesColor9,
                        //                     "dashStyle": seriesTypeStyle9,
                        //                     "tooltip": tooltip9
                        //                 }
                        //                 let graphSeries10 = {
                        //                     "type": seriesType10,
                        //                     "data": trendColumnValue9,
                        //                     "pointStart": 0,
                        //                     "name": series10,
                        //                     "color": seriesColor10,
                        //                     "dashStyle": seriesTypeStyle10,
                        //                     "tooltip": tooltip10
                        //                 }
                        //                 let graphSeriesStatus1 = false;
                        //                 for (let c1 = 0; c1 < trendColumnValue.length; c1++) {
                        //                     if (trendColumnValue[c1] != null && trendColumnValue[c1] != '') {
                        //                         graphSeriesStatus1 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus2 = false;
                        //                 for (let c2 = 0; c2 < trendColumnValue1.length; c2++) {
                        //                     if (trendColumnValue1[c2] != null && trendColumnValue1[c2] != '') {
                        //                         graphSeriesStatus2 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus3 = false;
                        //                 for (let c3 = 0; c3 < trendColumnValue2.length; c3++) {
                        //                     if (trendColumnValue2[c3] != null && trendColumnValue2[c3] != '') {
                        //                         graphSeriesStatus3 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus4 = false;
                        //                 for (let c4 = 0; c4 < trendColumnValue3.length; c4++) {
                        //                     if (trendColumnValue3[c4] != null && trendColumnValue3[c4] != '') {
                        //                         graphSeriesStatus4 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus5 = false;
                        //                 for (let c5 = 0; c5 < trendColumnValue4.length; c5++) {
                        //                     if (trendColumnValue4[c5] != null && trendColumnValue4[c5] != '') {
                        //                         graphSeriesStatus5 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus6 = false;
                        //                 for (let c6 = 0; c6 < trendColumnValue5.length; c6++) {
                        //                     if (trendColumnValue5[c6] != null && trendColumnValue5[c6] != '') {
                        //                         graphSeriesStatus6 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus7 = false;
                        //                 for (let c7 = 0; c7 < trendColumnValue6.length; c7++) {
                        //                     if (trendColumnValue6[c7] != null && trendColumnValue6[c7] != '') {
                        //                         graphSeriesStatus7 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus8 = false;
                        //                 for (let c8 = 0; c8 < trendColumnValue7.length; c8++) {
                        //                     if (trendColumnValue7[c8] != null && trendColumnValue7[c8] != '') {
                        //                         graphSeriesStatus8 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus9 = false;
                        //                 for (let c9 = 0; c9 < trendColumnValue8.length; c9++) {
                        //                     if (trendColumnValue8[c9] != null && trendColumnValue8[c9] != '') {
                        //                         graphSeriesStatus9 = true;
                        //                     }
                        //                 }
                        //                 let graphSeriesStatus10 = false;
                        //                 for (let c10 = 0; c10 < trendColumnValue9.length; c10++) {
                        //                     if (trendColumnValue9[c10] != null && trendColumnValue9[c10] != '') {
                        //                         graphSeriesStatus10 = true;
                        //                     }
                        //                 }
                        //                 if (graphSeriesStatus1) {
                        //                     graphSeries.push(graphSeries1);
                        //                 }
                        //                 if (graphSeriesStatus2) {
                        //                     graphSeries.push(graphSeries2);
                        //                 }
                        //                 if (graphSeriesStatus3) {
                        //                     graphSeries.push(graphSeries3);
                        //                 }
                        //                 if (graphSeriesStatus4) {
                        //                     graphSeries.push(graphSeries4);
                        //                 }
                        //                 if (graphSeriesStatus5) {
                        //                     graphSeries.push(graphSeries5);
                        //                 }
                        //                 if (graphSeriesStatus6) {
                        //                     graphSeries.push(graphSeries6);
                        //                 }
                        //                 if (graphSeriesStatus7) {
                        //                     graphSeries.push(graphSeries7);
                        //                 }
                        //                 if (graphSeriesStatus8) {
                        //                     graphSeries.push(graphSeries8);
                        //                 }
                        //                 if (graphSeriesStatus9) {
                        //                     graphSeries.push(graphSeries9);
                        //                 }
                        //                 if (graphSeriesStatus10) {
                        //                     graphSeries.push(graphSeries10);
                        //                 }
                        //
                        //                 Highcharts.chart('sideDivMetricGraphDesign', {
                        //                     title: {
                        //                         text: ''
                        //                     },
                        //
                        //                     xAxis: {
                        //                         gridLineWidth: 0,
                        //                         tickInterval: 1,
                        //                         title: {
                        //                             text: xAxisTitle,
                        //                             //align: 'center'
                        //                         },
                        //                         categories: trendColumnName
                        //                     },
                        //                     yAxis: {
                        //
                        //                         minorTickInterval: 0,
                        //                         title: {
                        //                             text: yAxisTitle,
                        //                         }
                        //                     },
                        //                     plotOptions: {
                        //                         series: {
                        //                             label: {
                        //                                 connectorAllowed: false
                        //                             },
                        //                         }
                        //                     },
                        //                     credits: {
                        //                         enabled: false
                        //                     },
                        //                     series: graphSeries,
                        //                     tooltip: {
                        //                         // formatter: function () {
                        //                         //     // The first returned item is the header, subsequent items are the
                        //                         //     // points
                        //                         //     return (this.points.map(function (point) {
                        //                         //             return point.series.name + ': ' + point.y + 'm';
                        //                         //         })
                        //                         //     );
                        //                         // },
                        //
                        //                         formatter: function () {
                        //                             let pointNum = parseFloat(this.y);
                        //                             let textValue = pointNum.toLocaleString();
                        //                             if (this.series && this.series.tooltipOptions.valuePrefix !== null && this.series.tooltipOptions.valuePrefix !== '') {
                        //                                 return this.x + '<br>' + this.series.name + '<br>' + this.series.tooltipOptions.valuePrefix + ' ' + textValue;
                        //                             } else if (this.series && this.series.tooltipOptions.valueSuffix !== null && this.series.tooltipOptions.valueSuffix !== '') {
                        //                                 return this.x + '<br>' + this.series.name + '<br>' + textValue + ' ' + this.series.tooltipOptions.valueSuffix;
                        //                             } else {
                        //                                 return this.x + '<br>' + this.series.name + '<br>' + textValue;
                        //                             }
                        //                         },
                        //                     },
                        //                     exporting: {
                        //                         enabled: true
                        //                     },
                        //                 });
                        //             }
                        //
                        //         })
                        // } else {
                        this.setState({
                            ...data,
                            loading: false
                        });
                        if (response.data.scorecardDomainsMapping && response.data.scorecardDomainsMapping.scorecardDomains.domainName !== null) {
                            this.setState({
                                domainName: response.data.scorecardDomainsMapping.scorecardDomains.domainName
                            })
                        }
                        if (response.data.scorecardSubDomainsMapping && response.data.scorecardSubDomainsMapping.subDomainName !== null) {
                            this.setState({
                                subDomainName: response.data.scorecardSubDomainsMapping.subDomainName
                            })
                        }

                        let updatedDate = new Date(data.updatedOn);
                        updatedDate.toString();

                        if (data.trendStatus) {
                            let series1 = data.metricTrend.series1;
                            let series2 = data.metricTrend.series2;
                            let series3 = data.metricTrend.series3;
                            let series4 = data.metricTrend.series4;
                            let series5 = data.metricTrend.series5;
                            let series6 = data.metricTrend.series6;
                            let series7 = data.metricTrend.series7;
                            let series8 = data.metricTrend.series8;
                            let series9 = data.metricTrend.series9;
                            let series10 = data.metricTrend.series10;

                            let seriesType1 = "line";
                            let seriesType2 = "line";
                            let seriesType3 = "line";
                            let seriesType4 = "line";
                            let seriesType5 = "line";
                            let seriesType6 = "line";
                            let seriesType7 = "line";
                            let seriesType8 = "line";
                            let seriesType9 = "line";
                            let seriesType10 = "line";
                            let seriesTypeStyle1 = "";
                            let seriesTypeStyle2 = "";
                            let seriesTypeStyle3 = "";
                            let seriesTypeStyle4 = "";
                            let seriesTypeStyle5 = "";
                            let seriesTypeStyle6 = "";
                            let seriesTypeStyle7 = "";
                            let seriesTypeStyle8 = "";
                            let seriesTypeStyle9 = "";
                            let seriesTypeStyle10 = "";

                            if (data.metricTrend.series1Type === 2) {
                                seriesType1 = "column";
                            } else if (data.metricTrend.series1Type === 3) {
                                seriesType1 = "area";
                            } else if (data.metricTrend.series1Type === 4) {
                                seriesTypeStyle1 = "longdash";
                            } else {
                                seriesType1 = "line";
                            }
                            if (data.metricTrend.series2Type === 2) {
                                seriesType2 = "column";
                            } else if (data.metricTrend.series2Type === 3) {
                                seriesType2 = "area";
                            } else if (data.metricTrend.series2Type === 4) {
                                seriesTypeStyle2 = "longdash";
                            } else {
                                seriesType2 = "line";
                            }
                            if (data.metricTrend.series3Type === 2) {
                                seriesType3 = "column";
                            } else if (data.metricTrend.series3Type === 3) {
                                seriesType3 = "area";
                            } else if (data.metricTrend.series3Type === 4) {
                                seriesTypeStyle3 = "longdash";
                            } else {
                                seriesType3 = "line";
                            }
                            if (data.metricTrend.series4Type === 2) {
                                seriesType4 = "column";
                            } else if (data.metricTrend.series4Type === 3) {
                                seriesType4 = "area";
                            } else if (data.metricTrend.series4Type === 4) {
                                seriesTypeStyle4 = "longdash";
                            } else {
                                seriesType4 = "line";
                            }
                            if (data.metricTrend.series5Type === 2) {
                                seriesType5 = "column";
                            } else if (data.metricTrend.series5Type === 3) {
                                seriesType5 = "area";
                            } else if (data.metricTrend.series5Type === 4) {
                                seriesTypeStyle5 = "longdash";
                            } else {
                                seriesType5 = "line";
                            }
                            if (data.metricTrend.series6Type === 2) {
                                seriesType6 = "column";
                            } else if (data.metricTrend.series6Type === 3) {
                                seriesType6 = "area";
                            } else if (data.metricTrend.series6Type === 4) {
                                seriesTypeStyle6 = "longdash";
                            } else {
                                seriesType6 = "line";
                            }
                            if (data.metricTrend.series7Type === 2) {
                                seriesType7 = "column";
                            } else if (data.metricTrend.series7Type === 3) {
                                seriesType7 = "area";
                            } else if (data.metricTrend.series7Type === 4) {
                                seriesTypeStyle7 = "longdash";
                            } else {
                                seriesType7 = "line";
                            }
                            if (data.metricTrend.series8Type === 2) {
                                seriesType8 = "column";
                            } else if (data.metricTrend.series8Type === 3) {
                                seriesType8 = "area";
                            } else if (data.metricTrend.series8Type === 4) {
                                seriesTypeStyle8 = "longdash";
                            } else {
                                seriesType8 = "line";
                            }
                            if (data.metricTrend.series9Type === 2) {
                                seriesType9 = "column";
                            } else if (data.metricTrend.series9Type === 3) {
                                seriesType9 = "area";
                            } else if (data.metricTrend.series9Type === 4) {
                                seriesTypeStyle9 = "longdash";
                            } else {
                                seriesType9 = "line";
                            }
                            if (data.metricTrend.series10Type === 2) {
                                seriesType10 = "column";
                            } else if (data.metricTrend.series10Type === 3) {
                                seriesType10 = "area";
                            } else if (data.metricTrend.series10Type === 4) {
                                seriesTypeStyle10 = "longdash";
                            } else {
                                seriesType10 = "line";
                            }

                            let seriesColor1 = "#" + data.metricTrend.series1Color;
                            let seriesColor2 = "#" + data.metricTrend.series2Color;
                            let seriesColor3 = "#" + data.metricTrend.series3Color;
                            let seriesColor4 = "#" + data.metricTrend.series4Color;
                            let seriesColor5 = "#" + data.metricTrend.series5Color;
                            let seriesColor6 = "#" + data.metricTrend.series6Color;
                            let seriesColor7 = "#" + data.metricTrend.series7Color;
                            let seriesColor8 = "#" + data.metricTrend.series8Color;
                            let seriesColor9 = "#" + data.metricTrend.series9Color;
                            let seriesColor10 = "#" + data.metricTrend.series10Color;

                            let trendColumnName = [];
                            let trendColumnValue = [];
                            let trendColumnValue1 = [];
                            let trendColumnValue2 = [];
                            let trendColumnValue3 = [];
                            let trendColumnValue4 = [];
                            let trendColumnValue5 = [];
                            let trendColumnValue6 = [];
                            let trendColumnValue7 = [];
                            let trendColumnValue8 = [];
                            let trendColumnValue9 = [];


                            let trendMappings = data.metricTrend.metricTrendMappings;
                            if (trendMappings !== null) {

                                let startTrendData = 0;
                                let endTrendData = trendMappings.length;

                                if (data.metricTrend.rowLimitType !== null && data.metricTrend.rowLimitType !== '' && data.metricTrend.rowLimitType === 1) {
                                    if (data.metricTrend.rowLimit !== null && data.metricTrend.rowLimit !== '') {
                                        startTrendData = endTrendData - data.metricTrend.rowLimit;

                                    }
                                } else {
                                    if (data.metricTrend.rowLimit !== null && data.metricTrend.rowLimit !== '') {
                                        endTrendData = data.metricTrend.rowLimit;

                                    }
                                }

                                for (let k = startTrendData; k < endTrendData; k++) {

                                    if (trendMappings[k].columnName !== null) {
                                        trendColumnName.push(trendMappings[k].columnName);
                                        if (trendMappings[k].columnValue !== null) {
                                            trendColumnValue.push(trendMappings[k].columnValue);
                                        } else {
                                            trendColumnValue.push(null);
                                        }
                                        if (trendMappings[k].columnValue1 !== null) {
                                            trendColumnValue1.push(trendMappings[k].columnValue1);
                                        } else {
                                            trendColumnValue1.push(null);
                                        }
                                        if (trendMappings[k].columnValue2 !== null) {
                                            trendColumnValue2.push(trendMappings[k].columnValue2);
                                        } else {
                                            trendColumnValue2.push(null);
                                        }
                                        if (trendMappings[k].columnValue3 !== null) {
                                            trendColumnValue3.push(trendMappings[k].columnValue3);
                                        } else {
                                            trendColumnValue3.push(null);
                                        }
                                        if (trendMappings[k].columnValue4 !== null) {
                                            trendColumnValue4.push(trendMappings[k].columnValue4);
                                        } else {
                                            trendColumnValue4.push(null);
                                        }
                                        if (trendMappings[k].columnValue5 !== null) {
                                            trendColumnValue5.push(trendMappings[k].columnValue5);
                                        } else {
                                            trendColumnValue5.push(null);
                                        }
                                        if (trendMappings[k].columnValue6 !== null) {
                                            trendColumnValue6.push(trendMappings[k].columnValue6);
                                        } else {
                                            trendColumnValue6.push(null);
                                        }
                                        if (trendMappings[k].columnValue7 !== null) {
                                            trendColumnValue7.push(trendMappings[k].columnValue7);
                                        } else {
                                            trendColumnValue7.push(null);
                                        }
                                        if (trendMappings[k].columnValue8 !== null) {
                                            trendColumnValue8.push(trendMappings[k].columnValue8);
                                        } else {
                                            trendColumnValue8.push(null);
                                        }
                                        if (trendMappings[k].columnValue9 !== null) {
                                            trendColumnValue9.push(trendMappings[k].columnValue9);
                                        } else {
                                            trendColumnValue9.push(null);
                                        }
                                    }


                                }
                            }

                            let xAxisTitle = '';
                            let yAxisTitle = '';
                            if (data.metricTrend.xTitle != null) {
                                xAxisTitle = data.metricTrend.xTitle;
                            }
                            if (data.metricTrend.yTitle != null) {
                                yAxisTitle = data.metricTrend.yTitle;
                            }

                            let series1HideStatus = true;
                            let series2HideStatus = true;
                            let series3HideStatus = true;
                            let series4HideStatus = true;
                            let series5HideStatus = true;
                            let series6HideStatus = true;
                            let series7HideStatus = true;
                            let series8HideStatus = true;
                            let series9HideStatus = true;
                            let series10HideStatus = true;

                            let graphSeries = [];
                            let valuePrefix1 = null;
                            let valueSuffix1 = null;
                            if (data.metricTrend.series1ValueType !== null && data.metricTrend.series1ValueType === 2) {
                                valuePrefix1 = '$';
                            } else if (data.metricTrend.series1ValueType !== null && data.metricTrend.series1ValueType === 3) {
                                valueSuffix1 = '%';
                            } else if (data.metricTrend.series1ValueType !== null && data.metricTrend.series1ValueType === 4) {
                                series1HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix2 = null;
                            let valueSuffix2 = null;
                            if (data.metricTrend.series2ValueType !== null && data.metricTrend.series2ValueType === 2) {
                                valuePrefix2 = '$';
                            } else if (data.metricTrend.series2ValueType !== null && data.metricTrend.series2ValueType === 3) {
                                valueSuffix2 = '%';
                            } else if (data.metricTrend.series2ValueType !== null && data.metricTrend.series2ValueType === 4) {
                                series2HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix3 = null;
                            let valueSuffix3 = null;
                            if (data.metricTrend.series3ValueType !== null && data.metricTrend.series3ValueType === 2) {
                                valuePrefix3 = '$';
                            } else if (data.metricTrend.series3ValueType !== null && data.metricTrend.series3ValueType === 3) {
                                valueSuffix3 = '%';
                            } else if (data.metricTrend.series3ValueType !== null && data.metricTrend.series3ValueType === 4) {
                                series3HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix4 = null;
                            let valueSuffix4 = null;
                            if (data.metricTrend.series4ValueType !== null && data.metricTrend.series4ValueType === 2) {
                                valuePrefix4 = '$';
                            } else if (data.metricTrend.series4ValueType !== null && data.metricTrend.series4ValueType === 3) {
                                valueSuffix4 = '%';
                            } else if (data.metricTrend.series4ValueType !== null && data.metricTrend.series4ValueType === 4) {
                                series4HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix5 = null;
                            let valueSuffix5 = null;
                            if (data.metricTrend.series5ValueType !== null && data.metricTrend.series5ValueType === 2) {
                                valuePrefix5 = '$';
                            } else if (data.metricTrend.series5ValueType !== null && data.metricTrend.series5ValueType === 3) {
                                valueSuffix5 = '%';
                            } else if (data.metricTrend.series5ValueType !== null && data.metricTrend.series5ValueType === 4) {
                                series5HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix6 = null;
                            let valueSuffix6 = null;
                            if (data.metricTrend.series6ValueType !== null && data.metricTrend.series6ValueType === 2) {
                                valuePrefix6 = '$';
                            } else if (data.metricTrend.series6ValueType !== null && data.metricTrend.series6ValueType === 3) {
                                valueSuffix6 = '%';
                            } else if (data.metricTrend.series6ValueType !== null && data.metricTrend.series6ValueType === 4) {
                                series6HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix7 = null;
                            let valueSuffix7 = null;
                            if (data.metricTrend.series7ValueType !== null && data.metricTrend.series7ValueType === 2) {
                                valuePrefix7 = '$';
                            } else if (data.metricTrend.series7ValueType !== null && data.metricTrend.series7ValueType === 3) {
                                valueSuffix7 = '%';
                            } else if (data.metricTrend.series7ValueType !== null && data.metricTrend.series7ValueType === 4) {
                                series7HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix8 = null;
                            let valueSuffix8 = null;
                            if (data.metricTrend.series8ValueType !== null && data.metricTrend.series8ValueType === 2) {
                                valuePrefix8 = '$';
                            } else if (data.metricTrend.series8ValueType !== null && data.metricTrend.series8ValueType === 3) {
                                valueSuffix8 = '%';
                            } else if (data.metricTrend.series8ValueType !== null && data.metricTrend.series8ValueType === 4) {
                                series8HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix9 = null;
                            let valueSuffix9 = null;
                            if (data.metricTrend.series9ValueType !== null && data.metricTrend.series9ValueType === 2) {
                                valuePrefix9 = '$';
                            } else if (data.metricTrend.series9ValueType !== null && data.metricTrend.series9ValueType === 3) {
                                valueSuffix9 = '%';
                            } else if (data.metricTrend.series9ValueType !== null && data.metricTrend.series9ValueType === 4) {
                                series9HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let valuePrefix10 = null;
                            let valueSuffix10 = null;
                            if (data.metricTrend.series10ValueType !== null && data.metricTrend.series10ValueType === 2) {
                                valuePrefix10 = '$';
                            } else if (data.metricTrend.series10ValueType !== null && data.metricTrend.series10ValueType === 3) {
                                valueSuffix10 = '%';
                            } else if (data.metricTrend.series10ValueType !== null && data.metricTrend.series10ValueType === 4) {
                                series10HideStatus = false;
                            } else {
                                //						nothing
                            }

                            let tooltip1 = {
                                "valuePrefix": valuePrefix1,
                                "valueSuffix": valueSuffix1
                            }
                            let tooltip2 = {
                                "valuePrefix": valuePrefix2,
                                "valueSuffix": valueSuffix2
                            }
                            let tooltip3 = {
                                "valuePrefix": valuePrefix3,
                                "valueSuffix": valueSuffix3
                            }
                            let tooltip4 = {
                                "valuePrefix": valuePrefix4,
                                "valueSuffix": valueSuffix4
                            }
                            let tooltip5 = {
                                "valuePrefix": valuePrefix5,
                                "valueSuffix": valueSuffix5
                            }
                            let tooltip6 = {
                                "valuePrefix": valuePrefix6,
                                "valueSuffix": valueSuffix6
                            }
                            let tooltip7 = {
                                "valuePrefix": valuePrefix7,
                                "valueSuffix": valueSuffix7
                            }
                            let tooltip8 = {
                                "valuePrefix": valuePrefix8,
                                "valueSuffix": valueSuffix8
                            }
                            let tooltip9 = {
                                "valuePrefix": valuePrefix9,
                                "valueSuffix": valueSuffix9
                            }
                            let tooltip10 = {
                                "valuePrefix": valuePrefix10,
                                "valueSuffix": valueSuffix10
                            }
                            let graphSeries1 = {
                                "type": seriesType1,
                                "data": trendColumnValue,
                                "pointStart": 0,
                                "name": series1,
                                "color": seriesColor1,
                                "dashStyle": seriesTypeStyle1,
                                "tooltip": tooltip1
                            }
                            let graphSeries2 = {
                                "type": seriesType2,
                                "data": trendColumnValue1,
                                "pointStart": 0,
                                "name": series2,
                                "color": seriesColor2,
                                "dashStyle": seriesTypeStyle2,
                                "tooltip": tooltip2
                            }
                            let graphSeries3 = {
                                "type": seriesType3,
                                "data": trendColumnValue2,
                                "pointStart": 0,
                                "name": series3,
                                "color": seriesColor3,
                                "dashStyle": seriesTypeStyle3,
                                "tooltip": tooltip3
                            }
                            let graphSeries4 = {
                                "type": seriesType4,
                                "data": trendColumnValue3,
                                "pointStart": 0,
                                "name": series4,
                                "color": seriesColor4,
                                "dashStyle": seriesTypeStyle4,
                                "tooltip": tooltip4
                            }
                            let graphSeries5 = {
                                "type": seriesType5,
                                "data": trendColumnValue4,
                                "pointStart": 0,
                                "name": series5,
                                "color": seriesColor5,
                                "dashStyle": seriesTypeStyle5,
                                "tooltip": tooltip5
                            }
                            let graphSeries6 = {
                                "type": seriesType6,
                                "data": trendColumnValue5,
                                "pointStart": 0,
                                "name": series6,
                                "color": seriesColor6,
                                "dashStyle": seriesTypeStyle6,
                                "tooltip": tooltip6
                            }
                            let graphSeries7 = {
                                "type": seriesType7,
                                "data": trendColumnValue6,
                                "pointStart": 0,
                                "name": series7,
                                "color": seriesColor7,
                                "dashStyle": seriesTypeStyle7,
                                "tooltip": tooltip7
                            }
                            let graphSeries8 = {
                                "type": seriesType8,
                                "data": trendColumnValue7,
                                "pointStart": 0,
                                "name": series8,
                                "color": seriesColor8,
                                "dashStyle": seriesTypeStyle8,
                                "tooltip": tooltip8
                            }
                            let graphSeries9 = {
                                "type": seriesType9,
                                "data": trendColumnValue8,
                                "pointStart": 0,
                                "name": series9,
                                "color": seriesColor9,
                                "dashStyle": seriesTypeStyle9,
                                "tooltip": tooltip9
                            }
                            let graphSeries10 = {
                                "type": seriesType10,
                                "data": trendColumnValue9,
                                "pointStart": 0,
                                "name": series10,
                                "color": seriesColor10,
                                "dashStyle": seriesTypeStyle10,
                                "tooltip": tooltip10
                            }
                            let graphSeriesStatus1 = false;
                            for (let c1 = 0; c1 < trendColumnValue.length; c1++) {
                                if (trendColumnValue[c1] !== null && trendColumnValue[c1] !== '') {
                                    graphSeriesStatus1 = true;
                                }
                            }
                            let graphSeriesStatus2 = false;
                            for (let c2 = 0; c2 < trendColumnValue1.length; c2++) {
                                if (trendColumnValue1[c2] !== null && trendColumnValue1[c2] !== '') {
                                    graphSeriesStatus2 = true;
                                }
                            }
                            let graphSeriesStatus3 = false;
                            for (let c3 = 0; c3 < trendColumnValue2.length; c3++) {
                                if (trendColumnValue2[c3] !== null && trendColumnValue2[c3] !== '') {
                                    graphSeriesStatus3 = true;
                                }
                            }
                            let graphSeriesStatus4 = false;
                            for (let c4 = 0; c4 < trendColumnValue3.length; c4++) {
                                if (trendColumnValue3[c4] !== null && trendColumnValue3[c4] !== '') {
                                    graphSeriesStatus4 = true;
                                }
                            }
                            let graphSeriesStatus5 = false;
                            for (let c5 = 0; c5 < trendColumnValue4.length; c5++) {
                                if (trendColumnValue4[c5] !== null && trendColumnValue4[c5] !== '') {
                                    graphSeriesStatus5 = true;
                                }
                            }
                            let graphSeriesStatus6 = false;
                            for (let c6 = 0; c6 < trendColumnValue5.length; c6++) {
                                if (trendColumnValue5[c6] !== null && trendColumnValue5[c6] !== '') {
                                    graphSeriesStatus6 = true;
                                }
                            }
                            let graphSeriesStatus7 = false;
                            for (let c7 = 0; c7 < trendColumnValue6.length; c7++) {
                                if (trendColumnValue6[c7] !== null && trendColumnValue6[c7] !== '') {
                                    graphSeriesStatus7 = true;
                                }
                            }
                            let graphSeriesStatus8 = false;
                            for (let c8 = 0; c8 < trendColumnValue7.length; c8++) {
                                if (trendColumnValue7[c8] !== null && trendColumnValue7[c8] !== '') {
                                    graphSeriesStatus8 = true;
                                }
                            }
                            let graphSeriesStatus9 = false;
                            for (let c9 = 0; c9 < trendColumnValue8.length; c9++) {
                                if (trendColumnValue8[c9] !== null && trendColumnValue8[c9] !== '') {
                                    graphSeriesStatus9 = true;
                                }
                            }
                            let graphSeriesStatus10 = false;
                            for (let c10 = 0; c10 < trendColumnValue9.length; c10++) {
                                if (trendColumnValue9[c10] !== null && trendColumnValue9[c10] !== '') {
                                    graphSeriesStatus10 = true;
                                }
                            }
                            if (graphSeriesStatus1 && series1HideStatus) {
                                graphSeries.push(graphSeries1);
                            }
                            if (graphSeriesStatus2 && series2HideStatus) {
                                graphSeries.push(graphSeries2);
                            }
                            if (graphSeriesStatus3 && series3HideStatus) {
                                graphSeries.push(graphSeries3);
                            }
                            if (graphSeriesStatus4 && series4HideStatus) {
                                graphSeries.push(graphSeries4);
                            }
                            if (graphSeriesStatus5 && series5HideStatus) {
                                graphSeries.push(graphSeries5);
                            }
                            if (graphSeriesStatus6 && series6HideStatus) {
                                graphSeries.push(graphSeries6);
                            }
                            if (graphSeriesStatus7 && series7HideStatus) {
                                graphSeries.push(graphSeries7);
                            }
                            if (graphSeriesStatus8 && series8HideStatus) {
                                graphSeries.push(graphSeries8);
                            }
                            if (graphSeriesStatus9 && series9HideStatus) {
                                graphSeries.push(graphSeries9);
                            }
                            if (graphSeriesStatus10 && series10HideStatus) {
                                graphSeries.push(graphSeries10);
                            }

                            Highcharts.chart('sideDivMetricGraphDesign', {
                                title: {
                                    text: ''
                                },

                                xAxis: {
                                    gridLineWidth: 0,
                                    tickInterval: 1,
                                    title: {
                                        text: xAxisTitle,
                                        //align: 'center'
                                    },
                                    categories: trendColumnName
                                },
                                yAxis: {

                                    minorTickInterval: 0,
                                    title: {
                                        text: yAxisTitle,
                                    }
                                },
                                plotOptions: {
                                    series: {
                                        label: {
                                            connectorAllowed: false
                                        },
                                    }
                                },
                                credits: {
                                    enabled: false
                                },
                                series: graphSeries,
                                tooltip: {
                                    // formatter: function () {
                                    //     // The first returned item is the header, subsequent items are the
                                    //     // points
                                    //     return (this.points.map(function (point) {
                                    //             return point.series.name + ': ' + point.y + 'm';
                                    //         })
                                    //     );
                                    // },

                                    formatter: function () {
                                        let pointNum = parseFloat(this.y);
                                        let textValue = pointNum.toLocaleString();
                                        if (this.series && this.series.tooltipOptions.valuePrefix !== null && this.series.tooltipOptions.valuePrefix !== '') {
                                            return this.x + '<br>' + this.series.name + '<br>' + this.series.tooltipOptions.valuePrefix + ' ' + textValue;
                                        } else if (this.series && this.series.tooltipOptions.valueSuffix !== null && this.series.tooltipOptions.valueSuffix !== '') {
                                            return this.x + '<br>' + this.series.name + '<br>' + textValue + ' ' + this.series.tooltipOptions.valueSuffix;
                                        } else {
                                            return this.x + '<br>' + this.series.name + '<br>' + textValue;
                                        }
                                    },
                                },
                                exporting: {
                                    enabled: true
                                },
                            });
                        }

                        // }
                    }else {
                        warningToast(response['tempMassage']);
                    }

                })
        }

    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isOpen){
            this.loadChart(nextProps.metricId)
        }
    }

    componentDidMount() {

    }

    toggle = () => {
        this.setState({
            domainName: undefined,
            subDomainName: undefined
        });
        this.props.toggle();
    };

    toggleAccordion = () => {
        this.setState({
            accordion: !this.state.accordion
        })
    };

    render() {
        return (
            <Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.toggle} size={"xl"}>
                    <ModalHeader toggle={this.toggle}>
                        <b>Metric Details</b> {this.state.domainName !== undefined ? "( Domain: " + this.state.domainName : ""} {this.state.subDomainName !== undefined ? ", Sub Domain: " + this.state.subDomainName : ""} {this.state.domainName && " )"}
                    </ModalHeader>
                    <ModalBody>
                        <BlockLoader  show={this.state.loading}>
                            <Row className={"pl-2 pr-2"}>
                                <Col md={'12'} style={{backgroundColor: '#f7f9fa', border: '#f7f9fa'}}>
                                    <Button style={{backgroundColor: '#f7f9fa', border: '#f7f9fa'}}
                                            onClick={this.toggleAccordion}
                                            aria-expanded={this.state.accordion}
                                            aria-controls="collapseOne">
                                        <span className={"text-primary"}>
                                            <b className={"ml-1"}>{this.state.metricName}</b>
                                        </span>
                                    </Button>
                                </Col>
                            </Row>
                            <Collapse style={{border: "#ffffff"}} isOpen={this.state.accordion} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
                                <div className={"pl-4"}>
                                    {this.state.customFields.map((items) => {
                                        return <div className={"w-100"}><b>{items.columnName}</b> - {items.columnValue ? items.columnValue : 'NA' }</div>
                                    })}
                                </div>
                            </Collapse>


                            <br />
                            <div className={"pl-4"}><b>Description</b> - {this.state.metricDescription}</div>
                            <div className={"pl-4"}><b>Metric Owners</b> - {this.state.ownerMappings &&
                            this.state.ownerMappings.map((items) => {
                                return <span><div className="ml-2 label label-info mr-2">{items.rootUsers.fname}{" "}{items.rootUsers.lname}</div><br /></span>
                            })
                            }</div>
                            <div className={"pl-4"}><b>Last Updated Date</b> - {formateDate(this.state['updatedOn'])}</div>
                            <br />
                            <br />
                            {/*<div className="row">*/}

                            <Row>
                                <Col md={'1'} className={"d-flex align-items-center"}>
                                    {this.state['prevMetricId'] ?
                                        <Button className={"p-0"} color={'white'}
                                                onClick={() => this.loadChart(this.state['prevMetricId'])}>
                                            <i className={"fal fa-caret-circle-left fa-3x"}/>
                                        </Button>
                                        :
                                        <div>{""}</div>
                                    }
                                </Col>
                                {this.state['metricTrend'] ?
                                    <Col md={'10'} style={{height: '430px'}}>
                                        <div id="sideDivMetricGraphDesign"
                                             style={{height: "100%", width: "100%"}}/>
                                    </Col>
                                    :
                                    <Col md={"10"}/>
                                }
                                <Col md={'1'} className={"d-flex align-items-center"}>
                                    {this.state['nextMetricId'] ?
                                        <Button className={"p-0"} color={"white"}
                                                onClick={() => this.loadChart(this.state['nextMetricId'])}>
                                            <i className={"fal fa-caret-circle-right fa-3x"}/>
                                        </Button>
                                        : <div>{""}</div>
                                    }
                                </Col>
                            </Row>
                            {/*</div>*/}
                        </BlockLoader>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
};
