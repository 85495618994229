export function setWhiteLabeling(response) {
    return dispatch => dispatch(setWhiteLabelingResponse(response))
}

export function setProfileData(profile) {
    return dispatch => dispatch(setProfile(profile))
}

export function setRoles(roles) {
    return dispatch => dispatch(setUserRoles(roles))
}

export function setUserResponse(user) {
    return dispatch => dispatch(setUserdetail(user))
}

export function setGlobalFilterNames(filterNames) {
    return dispatch => dispatch(setGlobalFilterNamesList(filterNames))
}
export function setGlobalFilterList(filter) {

    return dispatch => dispatch(setGlobalFiltersList(filter))
}

export function setAdminFlagValue(adminFlag) {

    return dispatch => dispatch(setAdminFlag(adminFlag))
}

export function setPasswordFlag(value) {
    return dispatch => {dispatch(setChangePasswordFlag(value))}
}


export const SET_CHANGE_PASSWORD_FLAG = "SET_CHANGE_PASSWORD_FLAG";
export const SET_CHANGE_PASSWORD_API_FLAG = "SET_CHANGE_PASSWORD_API_FLAG";
// export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
// export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
// export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const SET_PROFILE = "SET_PROFILE";
export const SET_USERDETAIL = "SET_USERDETAIL";
export const SET_GLOBAL_FILTER_NAMES = "SET_GLOBAL_FILTER_NAMES";
export const SET_GLOBAL_FILTER_LIST = "SET_GLOBAL_FILTER_LIST";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_WHITELABELING_RESPONE = "SET_WHITELABELING_RESPONE";
export const SET_ADMIN_FLAG = "SET_ADMIN_FLAG";



export const setWhiteLabelingResponse = response => ({
    type: SET_WHITELABELING_RESPONE,
    payload: {response}
});

export const setUserRoles = roles => ({
    type: SET_USER_ROLES,
    payload: {roles}
});

export const setGlobalFiltersList = filter => ({
    type: SET_GLOBAL_FILTER_LIST,
    payload: {filter}
});

export const setGlobalFilterNamesList = filterNames => ({
    type: SET_GLOBAL_FILTER_NAMES,
    payload: {filterNames}
});

export const setUserdetail = object => ({
    type: SET_USERDETAIL,
    payload: {object}
});


export const setProfile = profile => ({
    type: SET_PROFILE,
    payload: { profile }
});

export const setAdminFlag = adminFlag => ({
    type: SET_ADMIN_FLAG,
    payload: { adminFlag }
});

export const setChangePasswordAPIFlag = value => ({
    type: SET_CHANGE_PASSWORD_API_FLAG,
    payload: { value }
});

export const setChangePasswordFlag = value => ({
    type: SET_CHANGE_PASSWORD_FLAG,
    payload: { value }
});
