import {Redirect, Route, Switch} from 'react-router-dom';
import React, {Fragment, lazy, Suspense} from 'react';
import Loader from 'react-loaders';
import {LockScreen} from 'u-header';
import {ToastContainer} from 'react-toastify';
import Cookies from 'universal-cookie';
import {AUTH_BASE_URL, BASE_COOKIE, USER_BASE_API} from '../../Constants';
import EmbeddedScorecardDetailPage from '../../Pages/EmbeddedScorecardDetailPage/index1';
import PrivateRoute from "../../PrivateRoute";

// import NotFound from '../../Pages/404';

const Stragile = lazy(() => import('../../Pages'));

const NotFound = lazy(() => import('../../Pages/404'));

// const EmbeddedScorecardDetailPage = lazy(() => import('../../Pages/EmbeddedScorecardDetailPage/index1'));

let defaultRedirect = '/';
let authenticated;
let lockScreen;


const LoadingMessage = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-pulse"/>
            </div>
        </div>
    </div>
);

const AppMain = ({userObj}) => {


	const cookies = new Cookies();

	if (!cookies.get('lock')) {
		cookies.set('lock', false, { httpOnly: false,domain : BASE_COOKIE});
	} else {
		lockScreen = cookies.get('lock');
	}

	if (cookies.get('access_token')) {
		defaultRedirect = '/stragile/scorecards';
		lockScreen = cookies.get('lock').toString() === 'true';
		authenticated = true;
	} else {
		if(!window.location.href.includes('embeddedScorecard?token')){
			window.location.href = AUTH_BASE_URL;
			authenticated = true;

		}
	}

	const lock = () => {

		let url = window.location.href;
		cookies.set('lock', true, {httpOnly: false, domain: BASE_COOKIE});
		window.location.href = AUTH_BASE_URL + '#/pages/lock-screen?url=' + url;
	};

	const logout = () => {
		cookies.remove('access_token', {httpOnly: false, domain: BASE_COOKIE});
		cookies.remove('refresh_token', {httpOnly: false, domain: BASE_COOKIE});
		cookies.remove('lock', {httpOnly: false, domain: BASE_COOKIE});
		window.location.href = AUTH_BASE_URL + "/#/pages/login";
	};

	return (
		<Fragment>
			<LockScreen userObj={userObj}  baseCookie={BASE_COOKIE} authUrl={AUTH_BASE_URL} userBaseAPI={USER_BASE_API}/>

			{/* Components */}

            <Suspense fallback={<LoadingMessage />}>
                <Switch>

					<PrivateRoute
						path="/stragile"
						component={Stragile}
						authenticated={authenticated}
						lock={lockScreen} />

					<Route
						path="/embeddedScorecard"
						component={EmbeddedScorecardDetailPage}
						/>



                    <Route path="/404">
                        <NotFound />
                    </Route>
                    <Route path="**" render={() => (
                        <Redirect to="/404"/>
                    )}/>
                </Switch>
            </Suspense>

			<Route exact path="/" render={() => <Redirect to={defaultRedirect}/>}/>
			<ToastContainer/>
		</Fragment>
	);
};

export default AppMain;
