import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../AppMain';
import {setProfileData, setRoles, setUserResponse, setWhiteLabeling} from "../../reducers/action";
import Loader from "react-loaders";
import {ENV, ROLLBAR_ACCESS_TOKEN} from "../../Constants";
import Rollbar from 'rollbar';
import {userDetailsAPI,whitelabelingAPI} from "../../Utils/UtilService";
import CacheBuster from "./CacheBuster";

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPublic: false,
            closedSmallerSidebar: false,
            rollbar: null
        };
    }

    componentDidMount() {
        if(window.location.href.includes('embeddedScorecard?token')){
            this.setState({
                isPublic: true
            })
        }else {
            this.getUserDetails();
            this.getWhiteLabeling();
            this.setState({
                isPublic: false
            })
        }
    }

    getUserDetails = () => {
        userDetailsAPI().then(response => {
            this.props.dispatch(setRoles(response.data['authorityList']));
            this.props.dispatch(setUserResponse(response));
            this.props.dispatch(setProfileData(response.profile));
            this.setState({
                userResponse: response
            })
        })
    };

    getWhiteLabeling = () => {
        whitelabelingAPI()
            .then(response => {
                this.props.dispatch(setWhiteLabeling(response));
                this.setState({
                    whiteLabeling: response
                })
            })
    };

    setPayload = (props) => {
        let rollbar = new Rollbar({
            accessToken: ROLLBAR_ACCESS_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                ...ENV,
                CompanyId: props.rootComapnyId,
                Email: props.email,
                UserName: props.name
            }
        });
        this.setState({
            rollbar: rollbar
        })
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.profile !== null) {
            this.setPayload(nextProps.profile)
        }
    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;
        return (
          <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                  if (loading) return null;
                  if (!loading && !isLatestVersion) {
                      refreshCacheAndReload();
                  }
                  return(
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <div className={cx(
                            "app-container app-theme-" + colorScheme,
                            {'fixed-header': enableFixedHeader},
                            {'fixed-sidebar': enableFixedSidebar || width < 1250},
                            {'fixed-footer': enableFixedFooter},
                            {'closed-sidebar': enableClosedSidebar || width < 1250},
                            {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                            {'sidebar-mobile-open': enableMobileMenu},
                            {'body-tabs-shadow-btn': enablePageTabsAlt},
                        )}>
                            {this.state.userResponse && this.state.whiteLabeling ?
                                <AppMain userObj={this.state.userResponse}/>
                                :(window.location.href.includes('embeddedScorecard?token')) ?
                                    <AppMain userObj={null}/>
                            :
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <div className="text-center">
                                            <Loader type="ball-pulse"/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Fragment>
                )}
            />
                  );
              }}
          </CacheBuster>
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
    roles: state.UrlConstants.items,
    loading: state.UrlConstants.loading,
    error: state.UrlConstants.error,
    profile: state.UrlConstants.profile,
    userObj: state.UrlConstants.userdetailObject
});

export default withRouter(connect(mapStateToProp)(Main));
