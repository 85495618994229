import {request} from '../../Utils/UtilService'
import {STRAGILE_BASE_URL} from "../../Constants";

const GET_USER_METRICS = '/getUserMetrics';
const GET_SCORECARD_METRIC_DETAILS = '/getScorecardMetricDetails';

export async function getUserMetrics(page, limit, column, sort, metricName) {
    let sorting = '';
    let search = '';
    if(metricName !== undefined){
        search = '&titleName=' + metricName;
    }
    if(column !== undefined && sort !== undefined){
        sorting = '&column=' + column + '&sortStatus=' + sort;
    }
    const queryString = '?page=' + page + '&limit=' + limit + sorting + search;
    return await request({
        url: STRAGILE_BASE_URL + GET_USER_METRICS + queryString,
        method: 'GET'
    })
}

export async function getScorecardMetricDetails(id) {
    const queryString = '?metricId=' + id;
    return await request({
        url: STRAGILE_BASE_URL + GET_SCORECARD_METRIC_DETAILS + queryString,
        method: 'GET'
    })
}

export async function getScorecardMetricDetailsFilter(id, filter) {
    return await request({
        url: STRAGILE_BASE_URL + GET_SCORECARD_METRIC_DETAILS,
        method: 'POST',
        body: JSON.stringify({
            metricId: id,
            postFilterList: filter
        })
    })
}